<template>
  <div class="mainContainer container" style="">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Share Transaction #{{tranId}}</h5>
    </div>
    <hr>

    <div v-if="tran.ID">


      <div class="d-flex d-flex-center ">
        <div class="btn pageBtn saveTranDesc" @click="hideUpdateSection=!hideUpdateSection">Update Transaction Description</div>
      </div>

      <div v-if="!hideUpdateSection">
        <div class="remainCharsLeft">{{remainChars}} characters left</div>
        <div class="tranDetailsDiv">
          <textarea class="tranDetails" v-model="tran.DETAILS" ></textarea>
        </div>
        <div class="d-flex d-flex-center ">
          <div class="btn saveTranDesc pageBtn" @click="saveTranDesc"><font-awesome-icon class="fa" :icon="['fas', 'save']"/> {{tranDescBtnTxt}}</div>
        </div>
      </div>


      <div class="sectionHeader">Transaction preview</div>
      <c-tran :tran="tran" @thumbsUp="thumbsUp"></c-tran>

      <div class="agreement" v-if="tran.SHARED === '0'">By sharing, I agree to post the transaction information as shown in the preview box above to the Community board and I agree with the <a href="/community-rules">Community Rules</a>.</div>

      <div class="d-flex d-flex-center "  @click="toggleTranShare"   v-if="tran.SHARED === '0'">
        <div>
          <div class="btn shareBtn pageBtn" ><font-awesome-icon class="fa" :icon="['fas', 'share']"/> Share with the Community</div>
        </div>
      </div>

      <div class="d-flex d-flex-center " @click="toggleTranShare"  v-else>
        <div class="btn shareBtn pageBtn stop" ><font-awesome-icon class="fa" :icon="['fas', 'share']"/> Stop Sharing</div>
      </div>



    </div>
  </div>
</template>

<script>

import cTran from "@/components/Insights/cTran";

export default {
  name: 'ShareTran',
  components: {
    cTran,
  },
  data(){
    return{
      tranId:null,
      tran:{DETAILS:''},
      familyUsers:[],
      familyInfo:{},
      tranDescSaved:false,
      charLimit:500,
      tranDesc:null,
      hideUpdateSection:true,
    }
  },

  computed:{
    tranDescBtnTxt(){
      return this.tranDescSaved ? 'Saved' : 'Save Description';
    },
    remainChars(){
      return this.charLimit - this.tran.DETAILS.length;
    }

  },

  watch:{
    remainChars(newVal, oldVal) {
      if(newVal < 0){
        this.tran.DETAILS = this.tran.DETAILS.substring(0,this.charLimit);
      }
    }
  },


  methods:{
    getTran(tranId){
      this.axios
          .post(this.$hostApi,{task:'getKidTran',tranId:tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.tran = response.data;
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    saveTranDesc(){
      this.axios
          .post(this.$hostApi,{task:'saveTranDesc',tranId:this.tranId, tranDesc:this.tran.DETAILS},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.tranDescSaved = true;
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    toggleTranShare: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'toggleTranShare',tranId:this.tran.ID, currentState:this.tran.SHARED},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.tran.SHARED = response.data;
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    thumbsUp(tranId){
      this.tran.THUMBS_UP++;
    },
  },

  created(){
    this.tranId = this.$route.query.tranId;
    this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
    this.familyInfo = JSON.parse(localStorage.getItem('familyInfo'));
  },
  mounted() {
    this.getTran(this.tranId);
  }
}
</script>

<style scoped>
  .tranDetailsDiv{
    margin:2px;
  }

  .tranDetailsDiv textarea{
    width:100%;
    height:250px;
    background-color: #eeefef;
    border-radius: 5px;
    padding:10px;
    font-size:13px;
  }

  .saveTranDesc{
    background-color: #b7fa8b;
    color: #525151;
  }

  .shareBtn{
    background-color: #0dcaf0;
    color: #525151;
  }

  .shareBtn.stop{
    background-color: #f5bdb3;
    color: #525151;
  }

  .pageBtn{
    width:300px;
    margin:10px;

  }

  .fa{
    margin-right:10px;
    color: #525151;
  }

  .remainCharsLeft{
    font-size:12px;
    text-align: center;
  }

  .sectionHeader{
    color:#0dcaf0;
    padding:20px 0;
    font-size:20px;
    text-align: center;
  }

  .agreement{
    font-size:12px;
    text-align: center;
    margin-top:50px;
  }

</style>