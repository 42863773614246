<template>
  <div class="cTran" @click="toggleShortMode">
<!--    <div class="cTranLeft">
      <div class="cTranDate" v-html="genDateHtml(tran.TRAN_DATE)"></div>
    </div>-->
    <div class="cTranMiddle">
      <div class="whoamount" :class="numberColor(tran.AMOUNT)">{{tran.OWNER_NAME}} {{inout(tran.AMOUNT)}} {{tran.CURRENCY_CODE}} {{this.vueNumberFormat(Math.abs(tran.AMOUNT))}}</div>
      <div class="desc2">{{tranDesc}}</div>
    </div>
    <div class="cTranRight clickable" @click="thumbsUp(tran.ID)">
      <div><font-awesome-icon :icon="['fas', 'thumbs-up']" class="thumbs-up"/></div>
      <div class="thumbsUp">{{tran.THUMBS_UP}}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'cTran',
  props: {
    tran:{}
  },
  data(){
    return{
      shortMode:true,
      shortLength: 140,
    }
  },
  methods:{
    genDateHtml(d){
      var parts = d.split('-');
      const date = new Date();
      date.setMonth(parseInt(parts[1]))
      date.toLocaleString('en-US', { month: 'narrow' })
      return "<div><div class='cDay'>"+parts[2]+"</div><div class='cMonth'>"+date.toLocaleString('en-US', { month: 'short' })+"</div><div class='cYear'>"+parts[0]+"</div></div>";
    },

    numberColor:function(num){
      return num < 0 ? 'negative' : 'positive';
    },

    inout:function(num){
      return num < 0 ? 'spent' : 'received';
    },

    thumbsUp(tranId){
      this.$root.playClickSound();
      this.axios
          .post(this.$hostApi,{task:'thumbsUp',tranId:tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.$emit('thumbsUp',tranId);
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    truncateString(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },

    toggleShortMode(){
      this.shortMode = !this.shortMode;
    }

  },

  computed:{
    tranDescShortened(){
      return this.truncateString(this.tran.DETAILS,140)
    },

    tranDesc(){
      return this.shortMode ? this.truncateString(this.tran.DETAILS,this.shortLength) : this.tran.DETAILS;
    }
  },



}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cTran{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 1px 0 2px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
  margin:15px 0;
  border-radius: 3px;
  padding:10px;
  background-color: #d4efe5;
}

.cTranLeft{
  display: flex;
  justify-content: left;
  align-items: center;
  width:50px;
}

.cTranDate{
  text-align: center;
  font-size:12px;

  padding:2px 10px;
  background-color: #ffffff;
}

.cTranDate >>> .cMonth{
  font-size:16px;
  color:orange;
}
.cTranDate >>> .cDay{
  font-size:12px;
  color: #58595b;
}
.cTranDate >>> .cYear{
  font-size:10px;
}


.cTranMiddle{
  padding:0 10px;
  width:calc(100% - 30px);
}

.whoamount{
  font-size:13px;
  vertical-align: top;
  height:20px;
}

.desc2{
  padding:5px 0;
  font-size:11px;
  max-width: 100%;
  word-break: break-word;
  vertical-align: top;

}



.cTranRight{
  width:40px;
  color: #656565;
  text-align: center;
}

.thumbsUp{
  font-size:10px;
}


</style>
